import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Section, Button, TabContainer, CenterContainer, FreeSection, QuestionContainer} from './styles';
import TeamPackage from './TeamPackage';
import Enterprise from './Enterprise';
import QuestionTile from './QuestionTile';
import ContactUS from '../AboutPageV2/ContactUs';
import _ from 'lodash';
import {isValidEmail} from "../../utils/methods";
import { connect } from 'react-redux';
import {submitPricingEnterprise} from '../../redux/actions';
import Waiting from "../Waiting";

const validations = {
  email: (value) => {
    if (_.isUndefined(value.trim())) {
      return false
    } else if (!isValidEmail(value.trim())) {
      return false
    }
    return true
  },
  size: (value) => {
    if(!(value.trim())) {
      return false
    }
    return true;
  }
};

const questionList = [
  {
    question: 'How will I be charged for users not included in my plan?',
    answer: 'Simple, each plan includes a base number of eligible enrolled users. You will only be charged for each additional enrolled user based on your plan.'
  },
  {
    question: 'What is an enrolled user?',
    answer: 'An enrolled user is someone who has registered with your wellness program.'
  },
  {
    question: 'Can I add / remove enrolled users?',
    answer: "Yes, of course! You can easily add / remove users within your Admin portal."
  },
  {
    question: 'What happens if I want to change my plan?',
    answer: 'We can immediately upgrade your plan at the beginning of the following month. Once activated, your billing will reflect the change.'
  },
  {
    question: 'How long are the agreements?',
    answer: 'Each Core and Premium plan is a 12 month commitment. You can save up to 10% annually with 2 or 3 year agreements. Contact our Sales Team for more info.'
  },
  {
    question: "What if I can’t commit for 12 months?",
    answer: 'After an initial 3 month commitment, The starter tier offers month-to-month billing with a 1 month cancellation period.'
  },
  {
    question: 'What if I need more help?',
    answer: 'You can reach out to any of the members of our sales team at '
  }
];


class PricingDashboard extends Component {
  constructor(){
    super();
    this.state = {
      active: true,
      email: '',
      subject: '',
      helpDescription: '',
      disable: true
    }
  }

  toggleTabs = (status) => this.setState({active: status});

  handleTextChange = (e, field) => {
    const newState = {};
    newState[field] = e.target.value;
    this.setState(newState, ()=>this.validateFields());
  };

  validateFields = () => {
    const {email, subject, helpDescription} = this.state;
    if(validations.email(email) && validations.size(subject) && validations.size(helpDescription)){
      this.setState({
        disable: false
      })
    } else {
      this.setState({
        disable: true
      })
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {submitPricingEnterprise, history} = this.props;
    const {email, subject, helpDescription} = this.state;
    const payload = {
      email,
      subject,
      message: helpDescription
    };
    submitPricingEnterprise(history, payload);
  };

  renderPosterSection = (history) => (
    <Section>
      <div>
        <p>HOW WE MOVE FORWARD</p>
        <h1>Simplified pricing options to fit your company size and budget</h1>
        <Button onClick={()=> history.push('/contact')}>Try 1 Month Free</Button>
      </div>
      <div>
        <picture>
          <source srcSet="/public/images/PricingV2/ecommerce.png"/>
          <img src="/public/images/PricingV2/ecommerce.png"/>
        </picture>
      </div>
    </Section>
  );

  renderTabs = () => (
    <TabContainer active={this.state.active}>
      <div onClick={()=>this.toggleTabs(true)}>Team Package</div>
      <div onClick={()=>this.toggleTabs(false)}>Enterprise</div>
    </TabContainer>

  );

  render(){
    const {active, email, subject, helpDescription, disable} = this.state;
    const {history, isLoading} = this.props;
    return(
      <div>
        {isLoading && <Waiting />}
        {this.renderPosterSection(history)}
        {this.renderTabs()}
        <CenterContainer>
          {
            active ?
              <TeamPackage history={history}/>
              :
              <Enterprise
                handleTextChange={this.handleTextChange}
                email={email}
                subject={subject}
                helpDescription={helpDescription}
                handleSubmit={this.handleSubmit}
                disable={disable}
                history={history}
              />
          }
          <QuestionContainer>
            <div>
              <h1>
                Frequently Asked Question
              </h1>
              <p>Have a specific question? Check out our <strong>Product Guide</strong></p>
            </div>
            <div className="questionContainer">
              {
                questionList.map((item, index) => (
                  <QuestionTile key={index} item={item} index={index}/>
                ))
              }
            </div>
          </QuestionContainer>
        </CenterContainer>
        <FreeSection>
          <h3>Try Woliba with your team for free</h3>
          <p>Woliba’s connected company social network makes it easy for employees to increase
            their wellbeing while being recognized for doing so. This positive shift forward on an
            individual level starts a culture shift from the ground up.</p>
          <div>
            <h1>Ready to Dive in?</h1>
            <Button tryFree  marginTop="20px" onClick={()=>history.push('/contact')}>BOOK A DEMO</Button>
          </div>
        </FreeSection>
        <ContactUS history={history}/>
      </div>
    );
  }
}

PricingDashboard.propTypes = {
  history: PropTypes.object,
  submitPricingEnterprise: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  submitPricingEnterprise: (history, payload) => dispatch(submitPricingEnterprise(history, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingDashboard);
