import React from 'react';
import {Table, TableContainer} from "./styles";
import PropTypes from 'prop-types';

const wellness = [{
  feature: 'Challenge Management',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Wellness Initiative Management',
  starter: false,
  core: false,
  premium: true
}];
const engagement = [{
  feature: 'Engagement Dashboard',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Segment Participation',
  starter: false,
  core: false,
  premium: true
},{
  feature: 'Engagement Surveys',
  starter: false,
  core: false,
  premium: true
}];
const recognition = [{
  feature: 'Employee Recognition',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Rewards Program Report',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Recognition Activity',
  starter: false,
  core: false,
  premium: true
},{
  feature: 'Company Analytics',
  starter: false,
  core: false,
  premium: true
}];
const intranet = [{
  feature: 'User Directory',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Search',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'User, Role, Access Management',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Event Management',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Message Tools',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Task Management',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Initiative Management',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Customize Initiatives',
  starter: false,
  core: false,
  premium: true
}];
const data = [{
  feature: 'Annual Reporting',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Wellness Data and Analytics',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Employee Segmenting',
  starter: false,
  core: false,
  premium: true
},{
  feature: 'Health Data and Analysis',
  starter: false,
  core: false,
  premium: true
}];
const support = [{
  feature: 'Basic Support and Knowledge Base',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Wellness Committee Tools',
  starter: false,
  core: false,
  premium: true
},{
  feature: 'Account Management',
  starter: false,
  core: false,
  premium: true
}];

const TeamPackageTableAdmin = (props) => (
  <TableContainer>
    <div className={`tableHeader ${props.headerClass}`}>
      <div>Admin Features</div>
      <React.Fragment>
        <div>Starter</div>
        <div>Core</div>
        <div>Premium</div>
      </React.Fragment>
    </div>
    <Table>
      <div className="packageHeading" style={{color: '#2296B7', paddingTop: '30px'}}>WELLNESS</div>
      {
        wellness.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            {/*<div className="features">*/}
            {/*<span><img src="/public/images/PricingV2/admin-table.png"/></span>*/}
            {/*<span>{item.feature}</span>*/}
            {/*</div>*/}
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#E84F3B'}}>ENGAGEMENT</div>
      {
        engagement.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            {/*<div className="features">*/}
            {/*<span><img src="/public/images/PricingV2/admin-table.png"/></span>*/}
            {/*<span>{item.feature}</span>*/}
            {/*</div>*/}
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#71A148'}}>RECOGNITION</div>
      {
        recognition.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            {/*<div className="features">*/}
            {/*<span><img src="/public/images/PricingV2/admin-table.png"/></span>*/}
            {/*<span>{item.feature}</span>*/}
            {/*</div>*/}
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#FFA1A1'}}>INTRANET</div>
      {
        intranet.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            {/*<div className="features">*/}
            {/*<span><img src="/public/images/PricingV2/admin-table.png"/></span>*/}
            {/*<span>{item.feature}</span>*/}
            {/*</div>*/}
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#E8913B'}}>DATA</div>
      {
        data.map((item, index) => (
          <div className={`tableRow ${index % 2 !== 0 && 'evenRow'}`} key={index}>
            {/*<div className="features">*/}
            {/*<span><img src="/public/images/PricingV2/admin-table.png"/></span>*/}
            {/*<span>{item.feature}</span>*/}
            {/*</div>*/}
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table paddingBottom= {'50px'}>
      <div className="packageHeading" style={{color: '#392AC1'}}>SUPPORT</div>
      {
        support.map((item, index) => (
          <div className={`tableRow ${index % 2 !== 0 && 'evenRow'}`} key={index}>
            {/*<div className="features">*/}
            {/*<span><img src="/public/images/PricingV2/admin-table.png"/></span>*/}
            {/*<span>{item.feature}</span>*/}
            {/*</div>*/}
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
  </TableContainer>
);

TeamPackageTableAdmin.propTypes = {
  tableClass: PropTypes.string,
  headerClass: PropTypes.string
};

export default TeamPackageTableAdmin;