import React from 'react';
import {Table, TableContainer} from "./styles";
import PropTypes from 'prop-types';

const wellness = [{
  feature: 'Group and Personal Challenges',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Fitness Device Integration',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Daily Health Pro Tips',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Tailored Wellness Adventures',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Personalized Education',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Wellness Videos',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Targeted Health Improvement Resources',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Health and Wellness Webinars',
  starter: false,
  core: false,
  premium: true
}];
const recognition = [{
  feature: 'Peer Recognition',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Tagging',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Comments and Voting',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Discussion',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Leaderboard',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Rewards Program',
  starter: false,
  core: false,
  premium: true
}];
const intranet = [{
  feature: 'Employee Directory',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Search',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Global Social Feed',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Mobile Access',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Notification and Reminders',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Company Events',
  starter: true,
  core: true,
  premium: true
}];
const data = [{
  feature: 'Activity Data and History',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Health Risk Assessment',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Engagement Data',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Survey Data',
  starter: false,
  core: false,
  premium: true
}];
const support = [{
  feature: 'Knowledge Base',
  starter: true,
  core: true,
  premium: true
},{
  feature: 'Email Support Line',
  starter: false,
  core: true,
  premium: true
},{
  feature: 'Account Management',
  starter: false,
  core: false,
  premium: true
}];

const TeamPackageTable = (props) => (
  <TableContainer width={props.billingStatus && "97%"}>
    <div className="tableHeader">
      <div>Employee Features</div>
      <React.Fragment>
        <div>Starter</div>
        <div>Core</div>
        <div>Premium</div>
      </React.Fragment>
    </div>
    <Table>
      <div className="packageHeading" style={{color: '#2296B7', paddingTop: '30px'}}>WELLNESS</div>
      {
        wellness.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#71A148'}}>RECOGNITION</div>
      {
        recognition.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#FFA1A1'}}>INTRANET</div>
      {
        intranet.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table>
      <div className="packageHeading" style={{color: '#E8913B'}}>DATA</div>
      {
        data.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
    <Table paddingBottom= {'50px'}>
      <div className="packageHeading" style={{color: '#392AC1'}}>SUPPORT</div>
      {
        support.map((item, index) => (
          <div className={`tableRow ${index % 2 === 0 && 'evenRow'}`} key={index}>
            <div>{item.feature}</div>
            <React.Fragment>
              <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
              <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png"/> : null}</div>
            </React.Fragment>
          </div>
        ))
      }
    </Table>
  </TableContainer>
);

TeamPackageTable.propTypes = {
  tableClass: PropTypes.string,
  headerClass: PropTypes.string,
  billingStatus: PropTypes.bool
};

export default TeamPackageTable;