import React, {Component} from 'react';
import {DropDownContainer, PlanTitleSection, PosterContainer, TeamTabContainer} from "./styles";
import PropTypes from 'prop-types';
import PlanContainer from "./PlanContainer";
import TeamPackageTable from "./TeamPackageTable";
import TeamPackageTableAdmin from "./TeamPackageTableAdmin";
import AddOnSection from './AddOnSection';

class TeamPackage extends Component {
  constructor() {
    super();
    this.state = {
      active: true
    }
  }

  toggleTabs = (status) => this.setState({active: status});

  renderTabs = () => (
    <TeamTabContainer tabActive setTabWidth active={this.state.active}>
      <div onClick={()=>this.toggleTabs(true)}>Employee</div>
      <div onClick={()=>this.toggleTabs(false)}>Admin</div>
    </TeamTabContainer>
  );

  render() {
    const { active } = this.state;
    const { history } = this.props;

    return (
      <div className="teamPackage">
        <PlanTitleSection>
          <h1>{"Choose The Plan That's Right For Your Team"}</h1>
          <p>Teams is for small to mid-sized companies under 1k employees.</p>
        </PlanTitleSection>
        <div className="planTableContainer">
          <PlanContainer history={history}/>
          <DropDownContainer>
            <span>Compare Features</span>
            <span className="dropIcon"/>
          </DropDownContainer>
          {this.renderTabs()}
          {
            active ?
              <TeamPackageTable/>
              :
              <TeamPackageTableAdmin/>
          }
        </div>
        <PosterContainer>
          <div>
            <picture>
              <source srcSet="/public/images/PricingV2/Stock.png"/>
              <img src="/public/images/PricingV2/Stock.png"/>
            </picture>
          </div>
          <div>
            <h1>Woliba For Good</h1>
            <p>Woliba wants to support organizations and give back by providing special
                pricing for qualified nonprofit organizations and educational institutions.</p>
            <span onClick={() => history.push('/contact')}>Connect to Our Team to Qualify <img
              src="/public/images/PricingV2/right-angle.png"/></span>
          </div>
        </PosterContainer>
        <AddOnSection history={history}/>
      </div>
    );
  }
}

TeamPackage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default TeamPackage;